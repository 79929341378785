/**
 * @generated SignedSource<<9889562d76b91854d9bc966d7e6e2d14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BonusFallbackImage_offer$data = {
  readonly isProgramGroup: boolean | null;
  readonly steps: ReadonlyArray<{
    readonly __typename: string;
    readonly game?: {
      readonly thumbnail: string;
    } | null;
    readonly reward: {
      readonly __typename: "BingoTicketsOfferReward";
      readonly game: {
        readonly thumbnail: string;
      } | null;
    } | {
      readonly __typename: "FreeBetOfferReward";
      readonly game: {
        readonly thumbnail: string;
      } | null;
    } | {
      readonly __typename: "FreespinOfferReward";
      readonly game: {
        readonly thumbnail: string;
      } | null;
    } | {
      readonly __typename: "OddsBoostOfferReward";
      readonly game: {
        readonly thumbnail: string;
      } | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  }>;
  readonly " $fragmentType": "BonusFallbackImage_offer";
};
export type BonusFallbackImage_offer$key = {
  readonly " $data"?: BonusFallbackImage_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BonusFallbackImage_offer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Game",
    "kind": "LinkedField",
    "name": "game",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "layout",
            "value": "LANDSCAPE"
          }
        ],
        "kind": "ScalarField",
        "name": "thumbnail",
        "storageKey": "thumbnail(layout:\"LANDSCAPE\")"
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BonusFallbackImage_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isProgramGroup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "steps",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "reward",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "FreespinOfferReward",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "FreeBetOfferReward",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "OddsBoostOfferReward",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "BingoTicketsOfferReward",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "PlayRealMoneyOfferStep",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "PlayRealRoundsOfferStep",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "SafeBetOfferStep",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IBonusOffer",
  "abstractKey": "__isIBonusOffer"
};
})();

(node as any).hash = "e71b5be804cb8ad1d775c9f11637c944";

export default node;
