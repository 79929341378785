import type { FC } from 'react';
import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import { Icon } from '@pafcloud/base-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { isType } from '@pafcloud/collection-utils';
import type {
  BonusFallbackImage_offer$data,
  BonusFallbackImage_offer$key,
} from './__generated__/BonusFallbackImage_offer.graphql';
import { BonusColors } from './bonus-colors';
import { BannerImg, BannerPicture } from './flow/bonusFlowStyles';

const FallbackIcon = styled(Icon)({
  background: BonusColors.FallbackIconBackground,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',

  svg: {
    color: BonusColors.FallbackIcon,
    filter: 'drop-shadow(1rem 0.5rem 0.4rem rgba(0,0,0,0.2))',
  },
});

const offerFragment = graphql`
  fragment BonusFallbackImage_offer on IBonusOffer {
    isProgramGroup
    steps {
      __typename
      reward {
        __typename
        ... on FreespinOfferReward {
          game {
            thumbnail(layout: LANDSCAPE)
          }
        }

        ... on FreeBetOfferReward {
          game {
            thumbnail(layout: LANDSCAPE)
          }
        }

        ... on OddsBoostOfferReward {
          game {
            thumbnail(layout: LANDSCAPE)
          }
        }

        ... on BingoTicketsOfferReward {
          game {
            thumbnail(layout: LANDSCAPE)
          }
        }
      }

      ... on PlayRealMoneyOfferStep {
        game {
          thumbnail(layout: LANDSCAPE)
        }
      }

      ... on PlayRealRoundsOfferStep {
        game {
          thumbnail(layout: LANDSCAPE)
        }
      }

      ... on SafeBetOfferStep {
        game {
          thumbnail(layout: LANDSCAPE)
        }
      }
    }
  }
`;

type Props = {
  offer: BonusFallbackImage_offer$key | null;
  small?: boolean;
};

const getImageUrl = (offer?: BonusFallbackImage_offer$data | null) => {
  // TODO: Remove this ternary when goldenV2 is golden
  const theme = $buildEnv.theme === 'goldenV2' ? 'golden' : $buildEnv.theme;

  if (offer == null) {
    return null;
  }

  if (offer.steps.length === 0) {
    // group offers should always have a custom image, but we've agreed to use this
    // image with a gift as a general fallback.
    if (offer.isProgramGroup) {
      if (
        $buildEnv.theme === 'classic' ||
        $buildEnv.theme === 'classic-red' ||
        $buildEnv.theme === 'dreams' ||
        $buildEnv.theme === 'momentum' ||
        $buildEnv.theme === 'momentum-neon' ||
        $buildEnv.theme === 'golden' ||
        $buildEnv.theme === 'goldenV2' ||
        $buildEnv.theme === 'quirky-purple'
      ) {
        return `/static/images/claim-${theme}.jpg`;
      }
    }

    return null;
  }

  for (const step of offer.steps) {
    if (step.game != null) {
      if (isType(step, 'SafeBetOfferStep')) {
        return `/static/images/safebet-${$buildEnv.theme}.jpg`;
      }

      return step.game.thumbnail;
    }

    const isStepWithGameGraphics = isType(
      step.reward,
      'FreeBetOfferReward',
      'FreespinOfferReward',
      'OddsBoostOfferReward',
      'BingoTicketsOfferReward',
    );

    if (isStepWithGameGraphics) {
      if (isType(step.reward, 'FreeBetOfferReward')) {
        if (
          $buildEnv.theme === 'classic' ||
          $buildEnv.theme === 'momentum-neon' ||
          $buildEnv.theme === 'golden' ||
          $buildEnv.theme === 'goldenV2'
        ) {
          return `/static/images/freebet-${theme}.jpg`;
        }
      }

      return step.reward.game?.thumbnail;
    }
  }

  const firstStep = offer.steps[0];

  if (
    $buildEnv.theme === 'classic' ||
    $buildEnv.theme === 'classic-red' ||
    $buildEnv.theme === 'momentum' ||
    $buildEnv.theme === 'momentum-neon' ||
    $buildEnv.theme === 'golden' ||
    $buildEnv.theme === 'goldenV2' ||
    $buildEnv.theme === 'quirky-purple'
  ) {
    if (offer.steps.some((step) => isType(step, 'TurnoverOfferStep'))) {
      return `/static/images/turnover-${theme}.jpg`;
    }

    if (isType(firstStep, 'DepositOfferStep', 'RecentDepositOfferStep') && firstStep.reward != null) {
      return `/static/images/deposit-${theme}.jpg`;
    }

    if (isType(firstStep, 'ClaimOfferStep')) {
      return `/static/images/claim-${theme}.jpg`;
    }
  }

  if ($buildEnv.theme === 'dreams' && isType(firstStep, 'ClaimOfferStep')) {
    return `/static/images/claim-${$buildEnv.theme}.jpg`;
  }

  return null;
};

export const BonusFallbackImage: FC<Props> = ({ offer: offerKey, small = false }) => {
  const offer = useFragment(offerFragment, offerKey);

  if ($buildEnv.site === 'noaccountbet.se') {
    return (
      <BannerPicture>
        <BannerImg src="/static/images/bonus-nab.jpg" data-testid="fallback-banner" />
      </BannerPicture>
    );
  }

  const imageUrl = getImageUrl(offer);

  if (imageUrl != null) {
    if (
      $buildEnv.theme === 'classic' ||
      $buildEnv.theme === 'classic-red' ||
      $buildEnv.theme === 'dreams' ||
      $buildEnv.theme === 'momentum' ||
      $buildEnv.theme === 'momentum-neon' ||
      $buildEnv.theme === 'quirky-purple' ||
      $buildEnv.theme === 'golden' ||
      $buildEnv.theme === 'goldenV2'
    ) {
      return (
        <BannerPicture>
          <BannerImg src={imageUrl} data-testid="fallback-banner" />
        </BannerPicture>
      );
    }
  }

  if ($buildEnv.theme === 'dreams') {
    return (
      <BannerPicture>
        <BannerImg src={`/static/images/bonus-${$buildEnv.theme}.jpg`} data-testid="fallback-banner" />
      </BannerPicture>
    );
  }

  return (
    <BannerPicture>
      <FallbackIcon data-testid="bonus-fallback-icon" name="gift" size={small ? '2.5rem' : '5.5rem'} />
    </BannerPicture>
  );
};
